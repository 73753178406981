/*global global require */
/**
 * This file contains variables that need to be exposed globally (such as jQuery)
 */
var jQuery = require('jquery');
var moment = require('moment');
require('moment/locale/nl');
require('bootstrap');
require('./app.scss');

// Prevent auto-execution of scripts when no explicit dataType was provided (See gh-2432)
// This patch does not prevent NPM from reporting a vulnerability
// For comments see: https://curious-inc.atlassian.net/browse/DM-236

jQuery.ajaxPrefilter(function(options) {
	if(options.crossDomain) {
		options.contents.script = false;
	}
});

global.jQuery = jQuery;
global.$ = global.jQuery;

global.moment = moment;

window.smallScreenMediaQuery = '(max-width:968px)';

if(!('ontouchstart' in document.documentElement)) {
	document.documentElement.className += ' no-touch';
} else {
	document.documentElement.className += ' has-touch';
}
